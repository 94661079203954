const http = axios.create({
    baseURL: $("head").data("root"),
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    }
});
const httpAdmin = axios.create({
    baseURL: $("head").data("root"),
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    }
});

http.interceptors.response.use(function (response) {
    if (response.data.sessionExpired != undefined && response.data.sessionExpired) {
        Swal.fire({
            icon: "info",
            title: "Atenção",
            html: "Sua sessão expirou, você será redirecionado para o login",
        }).then(() => {
            location.href = $("head").data("root");
        });
        return new Promise(() => { });
    }

    return response;
}, function (error) {
    return Promise.reject(error);
});
httpAdmin.interceptors.response.use(function (response) {
    if (response.data.sessionExpired != undefined && response.data.sessionExpired) {
        Swal.fire({
            icon: "info",
            title: "Atenção",
            html: "Sua sessão expirou, você será redirecionado para o login",
        }).then(() => {
            location.href = $("head").data("root");
        });
        return new Promise(() => { });
    }

    return response;
}, function (error) {
    return Promise.reject(error);
});
